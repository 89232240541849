import { useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { ROUTES } from "../../../constants";
import { signOut } from "../../../supabase";

export const AccountPopover = (props: any) => {
  const { anchorEl, onClose, open } = props;
  const navigate = useNavigate();
  const firstname = useAppSelector((state) => state.app.admin?.firstname);
  const lastname = useAppSelector((state) => state.app.admin?.lastname);

  const handleSignOut = useCallback(() => {
    onClose?.();
    signOut();
    navigate(ROUTES.ADMIN);
  }, [onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Profil</Typography>
        <Typography color="text.secondary" variant="body2">
          {`${firstname} ${lastname}`}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Log af</MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
