import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TeamType } from "../../types/types";
import { setCookie } from "../../utils";

// Define a type for the slice state
interface TeamState {
  teams: Array<TeamType>;
  favoriteTeams: string[];
  editingTeam?: TeamType;
  selectedTeams: TeamType[];
}

// Define the initial state using that type
const initialState: TeamState = {
  teams: [],
  favoriteTeams: [],
  editingTeam: undefined,
  selectedTeams: [],
};

export const TeamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    setTeams: (state, action: PayloadAction<Array<TeamType>>) => {
      state.teams = action.payload;
    },
    createNewTeam: (state, action: PayloadAction<TeamType>) => {
      state.teams.push(action.payload);
    },
    removeTeam: (state, action: PayloadAction<string>) => {
      state.teams = state.teams.filter((team) => team.id !== action.payload);
    },
    updateTeam: (state, action: PayloadAction<{ teamId: string; team: TeamType }>) => {
      state.teams = state.teams.map((team) => (team.id === action.payload.teamId ? action.payload.team : team));
    },
    setFavoriteTeams: (state, action: PayloadAction<string[]>) => {
      state.favoriteTeams = action.payload;
    },
    addFavoriteTeam: (state, action: PayloadAction<{ teamId: string; tournamentDate: Date }>) => {
      state.favoriteTeams.push(action.payload.teamId);
      setCookie(state.favoriteTeams, action.payload.tournamentDate);
    },
    removeFavoriteTeam: (state, action: PayloadAction<{ teamId: string; tournamentDate: Date }>) => {
      state.favoriteTeams = state.favoriteTeams.filter((team) => team !== action.payload.teamId);
      setCookie(state.favoriteTeams, action.payload.tournamentDate);
    },
    setEditingTeam: (state, action: PayloadAction<TeamType | undefined>) => {
      state.editingTeam = action.payload;
    },
    setSelectedTeams: (state, action: PayloadAction<TeamType[]>) => {
      state.selectedTeams = action.payload;
    },
  },
});

export const {
  setTeams,
  createNewTeam,
  removeTeam,
  updateTeam,
  setFavoriteTeams,
  addFavoriteTeam,
  removeFavoriteTeam,
  setEditingTeam,
  setSelectedTeams,
} = TeamsSlice.actions;

export default TeamsSlice.reducer;
