import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ROUTES } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { TopNav } from "../admin/components/top-nav";
import { SideNav } from "../admin/components/side-nav";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useAppSelector } from "../../hooks";
import Login from "../../components/login";
import { ROLES } from "../../types/types";
import Tournaments from "./tournaments/tournaments";
import CreateTournament from "./tournaments/createTournament";
import { Users } from "../admin/users/users";
import CreateUser from "../admin/users/createUser";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

function SuperAdmin() {
  const [openNav, setOpenNav] = useState(false);
  const pathname = useLocation().pathname;
  const isSuperAdmin = useAppSelector((state) => state.app.admin)?.role === ROLES.SUPER_ADMIN;
  const location = useLocation();

  const getView = () => {
    switch (location.pathname) {
      case ROUTES.SUPER_ADMIN:
        return <Tournaments />;
      case ROUTES.SUPER_ADMIN_TOURNAMENTS:
        return <Tournaments />;
      case ROUTES.SUPER_ADMIN_CREATE_TOURNAMENT:
        return <CreateTournament />;
      case ROUTES.SUPER_ADMIN_USERS:
        return <Users />;
      case ROUTES.SUPER_ADMIN_CREATE_USERS:
        return <CreateUser />;
      default:
        return <h1>404 page does not exist</h1>;
    }
  };

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(() => {
    handlePathnameChange();
  }, [pathname]);

  return (
    <>
      {!isSuperAdmin ? (
        <Login open={true} returnCallback={() => {}} />
      ) : (
        <div style={{ width: "100%", height: "100%", minHeight: "100vh", backgroundColor: "white" }}>
          <TopNav onNavOpen={() => setOpenNav(true)} />
          <SideNav adminType="SUPER-ADMIN" onClose={() => setOpenNav(false)} open={openNav} />
          <LayoutRoot>
            <LayoutContainer>
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                }}
              >
                {getView()}
              </Box>
            </LayoutContainer>
          </LayoutRoot>
        </div>
      )}
    </>
  );
}

export default SuperAdmin;
