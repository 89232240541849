import { Typography } from "@mui/material";
import { useState } from "react";
import Login from "./login";
import DrawerMenu from "./drawer";
import { useAppDispatch, useAppSelector } from "../hooks";
import { SxProps } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../constants";
import { setSelectedPulje } from "../features/puljer/PuljeSlice";
import MenuIcon from "@mui/icons-material/Menu";
import FavoritesDrawer from "./favorites-drawer";
import { Pulje } from "../types/types";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { getRelativePath } from "../utils";

type Props = {};

const favoriteButtonStyles: SxProps = {
  cursor: "pointer",
  color: "#CDCDCD",
};

const Topbar = (props: Props) => {
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const tournament = useAppSelector((state) => state.tournament.tournament);
  const location = useLocation();
  const puljer = useAppSelector((state) => state.puljer.puljer);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getHeader = () => {
    return (
      <div className="column-container">
        <Typography
          className="hidden-authentication-button"
          onDoubleClick={() => setShowLoginDialog(true)}
          variant="h4"
          sx={{ fontSize: "1.4em", color: "white" }}
        >
          {tournament.name}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "0.7em", position: "relative", top: "3px", color: "white" }}>
          {getSubHeader()}
        </Typography>
      </div>
    );
  };

  const getSubHeader = () => {
    switch (getRelativePath(location.pathname)) {
      case ROUTES.ROOT:
        return "";
      case ROUTES.MATCHES:
        return "";
      case ROUTES.MATCH_DETAILS:
        return "";
      case ROUTES.TEAM_MATCHES:
        return "";
      case ROUTES.TOURNAMENT:
        return "";
      case ROUTES.KNOCK_OUT:
        return puljer.find((pulje) => pulje.id === selectedPulje?.id)?.puljeName;
      case ROUTES.GROUPS:
        return puljer.find((pulje) => pulje.id === selectedPulje?.id)?.puljeName;
      case ROUTES.ADMIN:
        return;
      default:
        console.error(`Sorry, we are out of routes`);
    }
  };

  const getContent = () => {
    switch (getRelativePath(location.pathname)) {
      case ROUTES.ROOT:
        return (
          <>
            <div />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.MATCHES:
        return (
          <>
            <div />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.MATCH_DETAILS:
        return (
          <>
            <ArrowBack onClick={() => navigate(-1)} sx={{ color: "white" }} />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.TEAM_MATCHES:
        return (
          <>
            <ArrowBack onClick={() => navigate(-1)} sx={{ color: "white" }} />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.FAVORITES:
        return (
          <>
            <FavoritesDrawer />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.KNOCK_OUT:
        return (
          <>
            <DrawerMenu
              onItemSelected={(pulje: Pulje) => dispatch(setSelectedPulje(pulje))}
              options={puljer.map((pulje) => {
                return { label: pulje.puljeName, id: pulje.id };
              })}
              selectedItem={selectedPulje}
              Icon={MenuIcon}
            />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.GROUPS:
        return (
          <>
            <DrawerMenu
              onItemSelected={(pulje: Pulje) => dispatch(setSelectedPulje(pulje))}
              options={puljer.map((pulje) => {
                return { label: pulje.puljeName, id: pulje.id };
              })}
              selectedItem={selectedPulje}
              Icon={MenuIcon}
            />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.TOURNAMENT:
        return (
          <>
            <div />
            {getHeader()}
            <div />
          </>
        );
      case ROUTES.ADMIN:
        return (
          <>
            <div />
            {getHeader()}
            <div />
          </>
        );
      default:
        console.error(`Sorry, we are out of routes`);
    }
  };

  return (
    <>
      <Login open={showLoginDialog} returnCallback={() => setShowLoginDialog(false)} />
      <div className="topbar-container">{getContent()}</div>
    </>
  );
};

export default Topbar;
