import PropTypes from "prop-types";
import { Box, Divider, Drawer, Stack, Typography, useMediaQuery } from "@mui/material";
import { adminItems, superAdminItems } from "./config";
import { SideNavItem } from "./side-nav-item";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/tf.png";
import { getTitle } from "../../../utils";

interface Props {
  adminType: "ADMIN" | "SUPER-ADMIN";
  open: boolean;
  onClose: () => void;
}

export const SideNav = (props: Props) => {
  const { adminType, onClose, open } = props;
  const pathname = useLocation().pathname;
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" p={4}>
        <img src={logo} height={70} />
        <Typography letterSpacing={3} variant="h6" marginTop={2}>
          {getTitle()}
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
        }}
      >
        <Stack
          component="ul"
          spacing={1.5}
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          {(adminType === "SUPER-ADMIN" ? superAdminItems : adminItems).map((item) => {
            const active = item.paths.some((path) => path === pathname);

            return (
              <SideNavItem
                active={active}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                path={item.paths?.[0]}
                title={item.title}
              />
            );
          })}
        </Stack>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
