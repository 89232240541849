// @ts-nocheck

import { Typography } from "@mui/material";
import { SPORT_TYPE } from "../types/types";
import { getSportType, getTitle } from "../utils";

type PropType = {};

function LoadingIcon(props: PropType) {
  return (
    <div className="progress-container">
      <div className="main-fader">
        <div className="loader">
          <svg width="396" height="186.5988019548497" viewBox="0 0 350 164.923183545953" className="css-1j8o68f">
            <defs id="SvgjsDefs4178">
              <linearGradient id="SvgjsLinearGradient4183">
                <stop id="SvgjsStop4184" stopColor="#ef4136" offset="0"></stop>
                <stop id="SvgjsStop4185" stopColor="#fbb040" offset="1"></stop>
              </linearGradient>
            </defs>
            <g
              id="SvgjsG4179"
              featurekey="symbolFeature-0"
              transform="matrix(1.3888888888888888,0,0,1.3888888888888888,106.05555555555556,-7.083333200878567)"
              fill="url(#SvgjsLinearGradient4183)"
            >
              <g xmlns="http://www.w3.org/2000/svg">
                <path
                  className="path-1"
                  fill="url(#SvgjsLinearGradient4183)"
                  d="M43.1,49.6c-11.6-1.5-24.9-3.2-28.4-3.4c-1.4,3.1-6.2,14.3-6.1,21.4c5.2,12.3,16,21.9,28.8,25.6   c7.7-4,16.6-12.7,19.1-15.2C52.5,71.4,44.9,53.9,43.1,49.6z"
                ></path>
                <path
                  className="path-2"
                  fill="url(#SvgjsLinearGradient4183)"
                  d="M41.4,94.1c2.9,0.6,5.7,0.8,8.6,0.8c16.7,0,31.9-9.2,39.7-23.9c-12.3,7.7-27.3,8.5-30.9,8.6   C56.9,81.6,49.1,89.3,41.4,94.1z"
                ></path>
                <path
                  className="path-3"
                  fill="url(#SvgjsLinearGradient4183)"
                  d="M43,46.7c2.6-7,16.8-21.8,20.5-25.6C57.2,9.6,49,5.8,47.1,5.1c-13,0.8-24.9,7.3-32.9,17.7   c-0.7,4.8,0,17.4,0.5,20.6C18.8,43.6,32.1,45.3,43,46.7z"
                ></path>
                <path
                  className="path-4"
                  fill="url(#SvgjsLinearGradient4183)"
                  d="M11.1,27.4C7.1,34.2,5,42,5,49.9c0,3.8,0.5,7.6,1.4,11.3c1.5-7,4.8-14.5,5.7-16.6   C11.4,41.9,11,33.3,11.1,27.4z"
                ></path>
                <path
                  className="path-5"
                  fill="url(#SvgjsLinearGradient4183)"
                  d="M65.7,22.9c-7.6,7.7-18.9,20.5-20.2,25.2c1.3,3,9.6,22.2,13.5,28.6c3.7-0.1,18.1-1.1,29.5-8.3   c0.5-3.1,3.4-21.2,0-31C87.1,35.7,77.2,25.2,65.7,22.9z"
                ></path>
                <path
                  className="path-6"
                  fill="url(#SvgjsLinearGradient4183)"
                  d="M95,49.9c0-5.6-1-11-3-16.1l-0.7,2.7c3.1,9.2,1.3,24.1,0.4,30.3C93.9,61.4,95,55.8,95,49.9z"
                ></path>
                <path
                  className="path-7"
                  fill="url(#SvgjsLinearGradient4183)"
                  d="M66.2,20.1c10.3,2.1,18.9,9.7,22.9,13.7l1-4.1c-7.2-14.2-21.2-23.4-37-24.5C57.1,7.7,62,12.3,66.2,20.1z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <Typography
        sx={{ color: "lightgrey", fontWeight: "100", letterSpacing: 5, top: 40, position: "relative" }}
        variant="h5"
      >
        {getTitle().toUpperCase()}
      </Typography>
    </div>
  );
}

export default LoadingIcon;
