import Topbar from "../../components/Topbar";
import { useAppSelector } from "../../hooks";
import TabBar from "../tabbar";
import ErrorPage from "../other/errorPage";
import { useMemo } from "react";
import MatchComponent from "../../components/match";
import { Match } from "../../types/types";

function TeamMatches() {
  const selectedTeams = useAppSelector((state) => state.teams.selectedTeams);
  const selectedTeamIds = selectedTeams.map((team) => team.id);
  const matches = useAppSelector((state) => state.matches.groupMatches);
  if (selectedTeams.length === 0) return <ErrorPage />;

  const filteredMatches = useMemo(() => {
    return matches
      .filter(
        (match) => selectedTeamIds.includes(match.homeTeam?.id ?? "") || selectedTeamIds.includes(match.awayTeam?.id ?? "")
      )
      .sort((a, b) => a.startTime + a.location.delay * 60 - (b.startTime + b.location.delay * 60));
  }, [matches]);

  return (
    <>
      <Topbar />
      <div className="content-container">
        <div className="match-list">
          {filteredMatches && filteredMatches.length > 0 && (
            <>
              <div className="matches-list-header">
                {selectedTeams.length === 1
                  ? `Matches for ${selectedTeams[0].teamName}`
                  : `Matches for group ${matches[0].group?.name}`}
              </div>
              {filteredMatches?.map((match: Match) => (
                <MatchComponent key={match.id} match={match} />
              ))}
            </>
          )}
        </div>
      </div>
      <TabBar />
    </>
  );
}
export default TeamMatches;
