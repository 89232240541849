import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import TextField from "@mui/material/TextField";
import { SPORT_TYPE, TeamType, Tournament } from "../../../types/types";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { dbCreateTournament, dbUpdateTournament } from "../../../api/api";
import NotificationService from "../../../components/notification/NotificationService";
import { getSportType } from "../../../utils";

interface Props {}

const CreateTournament = (props: Props) => {
  const {} = props;
  const navigate = useNavigate();

  const editingTournament = useAppSelector((state) => state.tournament.editingTournament);
  const [tournamentName, setTournamentName] = useState(editingTournament?.name ?? "");
  const [date, setDate] = useState<Moment | null>(
    editingTournament?.date
      ? moment.unix(editingTournament?.date)
      : moment().set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
  );
  const [link, setLink] = useState(editingTournament?.link ?? "");
  const [isActive, setIsActive] = useState(editingTournament?.isActive);
  const [isGoingLive, setIsGoingLive] = useState(editingTournament?.isGoingLive);
  const [showInTournamentDates, setShowInTournamentDates] = useState(editingTournament?.showInTournamentDates ?? true);
  const [sportType, setSportType] = useState<SPORT_TYPE>(editingTournament?.sportType ?? getSportType());

  const updateTournamentHandler = () => {
    const body = {
      ...editingTournament,
      name: tournamentName,
      date: date?.unix(),
      showInTournamentDates,
      isActive,
      isGoingLive,
      link,
      sportType,
    } as Tournament;

    dbUpdateTournament(body)
      .then(() => {
        navigate(ROUTES.SUPER_ADMIN_TOURNAMENTS);
        NotificationService.showToast({
          severity: "success",
          text: "Turneringen blev opdateret.",
          duration: 3000,
        });
      })
      .catch((error) => {
        NotificationService.showToast({
          severity: "error",
          text: "Kunne ikke opdatere banen.",
          duration: 3000,
        });
        console.error(error);
      });
  };

  const createTournamentHandler = () => {
    const body = {
      date: date?.unix(),
      name: tournamentName,
      isActive,
      isGoingLive,
      showInTournamentDates,
      link,
      sportType,
    } as Tournament;

    dbCreateTournament(body)
      .then((id) => {
        navigate(ROUTES.SUPER_ADMIN_TOURNAMENTS);
        NotificationService.showToast({
          severity: "success",
          text: "Turneringen blev oprettet",
          duration: 3000,
        });
      })
      .catch((e) => {
        console.error(e);
        NotificationService.showToast({
          severity: "error",
          text: `Tournament could not be created.`,
          duration: 3000,
        });
      });
  };

  return (
    <>
      <Container maxWidth={"md"}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="flex" spacing={4}>
            <Typography variant="h4">{editingTournament ? "Opdater turnering" : "Opret turnering"}</Typography>
          </Stack>
          <Card>
            <form autoComplete="off" noValidate onSubmit={() => {}}>
              <Card>
                <CardHeader
                  subheader="Udfyld følgende oplysninger:"
                  title={editingTournament ? "Opdater turnering" : "Ny turnering"}
                />
                <CardContent sx={{ pt: 0 }}>
                  <Box sx={{ m: -1.5 }}>
                    <Grid container>
                      <Grid xs={12} md={6} padding={1} item>
                        <TextField
                          fullWidth
                          label="Sportsgren"
                          name="sport-type"
                          onChange={(e) => setSportType(e.target.value as SPORT_TYPE)}
                          required
                          select
                          SelectProps={{ native: true }}
                          value={sportType}
                        >
                          {Object.values(SPORT_TYPE).map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          fullWidth
                          label="Navn"
                          name="turnering"
                          onChange={(e) => setTournamentName(e.target.value)}
                          required
                          value={tournamentName}
                          sx={{ margin: "10px 0px" }}
                        />
                        <DateTimePicker
                          value={date}
                          label="Dato og tid"
                          onChange={(value) => setDate(value)}
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="DD/MM/YYYY HH:mm"
                          ampm={false}
                        />
                        <TextField
                          fullWidth
                          label="Link"
                          name="link"
                          onChange={(e) => setLink(e.target.value)}
                          required
                          value={link}
                          sx={{ margin: "10px 0px" }}
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              checked={showInTournamentDates}
                              onChange={(event) => setShowInTournamentDates(event.target.checked)}
                            />
                          }
                          label="Vis på forsiden over turneringsdatoer"
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox checked={isGoingLive} onChange={(event) => setIsGoingLive(event.target.checked)} />
                          }
                          label="Vis 'LIVE'-label på forsiden"
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={<Checkbox checked={isActive} onChange={(event) => setIsActive(event.target.checked)} />}
                          label="Aktiv turnering"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button variant="outlined" onClick={() => navigate(ROUTES.SUPER_ADMIN_TOURNAMENTS)}>
                    Fortryd
                  </Button>
                  <Button
                    disabled={!tournamentName}
                    variant="contained"
                    onClick={editingTournament ? updateTournamentHandler : createTournamentHandler}
                  >
                    {editingTournament ? "Opdater" : "Opret"}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default CreateTournament;
