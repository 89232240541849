import { Match } from "../../types/types";
import { Nationality, Pot, ExtendedTeamType } from "./data";

export const getEmoji = (nationality?: Nationality) => {
  if (!nationality) return ''
  switch (nationality) {
    case Nationality.DENMARK:
      return "🇩🇰";
    case Nationality.NORWAY:
      return "🇳🇴";
    case Nationality.ENGLAND:
      return "🏴󠁧󠁢󠁥󠁮󠁧󠁿";
    case Nationality.BELGIUM:
      return "🇧🇪";
    case Nationality.FRANCE:
      return "🇫🇷";
    case Nationality.NETHERLANDS:
      return "🇳🇱";
    case Nationality.SWITZERLAND:
      return "🇨🇭";
    case Nationality.GERMANY:
      return "🇩🇪";
    default:
      "-";
  }
};

const randomGenerator = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const findOpponent = (myTeam: ExtendedTeamType, searchPot: Pot, existingMatches: Match[]) => {
  // Club filtering
  const filteredPot = searchPot.teams.filter((team) => team.club?.id !== myTeam.club?.id);

  // Pot filtering
  let validModstandere = filteredPot.filter(
    (team) => !team.potMatches.includes(searchPot.potId) && !team.potMatches.includes(myTeam.potId)
  );

  // Man kan max møde 2 hold fra samme land

  const mostFrequentNation = getMostFrequentNation(myTeam)

  // Hvis der er allerede er 2 modstandere fra denne nation, må vi ikke trække flere hold fra denne nation
  if (mostFrequentNation.numberOfOpponents === 2) {
    validModstandere = validModstandere.filter((team) => team.nationality !== mostFrequentNation.nationality)
  }

  const randomIndex = randomGenerator(validModstandere.length);

  const opponent = validModstandere[randomIndex];

  return opponent;
};


export const getMostFrequentNation = (myTeam: ExtendedTeamType) => {

  // Lav en liste med alle nationaliteter
  let nationalities = Object.values(Nationality).map(obj => {
    return {
      nationality: obj,
      numberOfOpponents: 0
    }
  });


  // Find ud af hvor mange forskellige lande de nuværende modstanderne kommer fra
  myTeam.opponents.forEach(op => {
    nationalities = nationalities.map(n => {
      if (n.nationality === op.nationality) {
        return { ...n, numberOfOpponents: n.numberOfOpponents + 1 }
      } else {
        return n
      }
    })
  })

  // Returner den nation hvor flest modstandere kommer fra
  return nationalities.sort((a, b) => b.numberOfOpponents - a.numberOfOpponents)[0]
}