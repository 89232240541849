import { useParams } from "react-router-dom";
import EarlierMatches from "../../components/earlier-matches";
import MatchData from "../../components/match-data";
import Topbar from "../../components/Topbar";
import { useAppSelector } from "../../hooks";
import TabBar from "../tabbar";
import ErrorPage from "../other/errorPage";

function MatchDetails() {
  const { id } = useParams();
  const match = useAppSelector((state) => state.matches.groupMatches).find((match) => match.id === id);

  if (!match) return <ErrorPage />;

  return (
    <>
      <Topbar />
      <div className="match-details-container">
        {match && (
          <>
            <MatchData match={match} />
            <EarlierMatches match={match} />
          </>
        )}
      </div>
      <TabBar />
    </>
  );
}
export default MatchDetails;
